import React from 'react';
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";
import {careersEnabled, getLink, getRoutes} from "../../components/shared/routes";
import OfferPage from "../../components/shared/OfferPage/OfferPage";
import DMSLogo from "../../images/Careers/deeplai-career-digital_marketing_specialist.svg";

const CareersDigitalMarketingSpecialist = () => {
    const routes = getRoutes();

    if (!careersEnabled.list) {
        return <RedirectTo/>;
    }
    if (!careersEnabled.digitalMarketingSpecialist) {
        return <RedirectTo url={getLink([routes.Careers])}/>;
    }

    return (
        <OfferPage
            image={DMSLogo}
            imageAlt={'deeplai career Digital Marketing Specialist'}
            name={routes.CareersDigitalMarketingSpecialist.pageTitle}
            scopeOfDuties={<><p>SaaS marketplace B2B and B2B2C</p><p>Develop strong and innovative
                strategies to promote the business brand and services</p><p>Understand the workings of
                all marketing tools and techniques like PPC, SEO, SEM, email, social media, and display
                advertising towards promoting appropriately the profit goal of the organization</p>
                <p>Coordinate and challenge the collaboration with external digital agencies of the
                    organization</p><p>Maintain campaign reporting, metrics, and site analysis</p>
                <p>Oversee day-to-day activities of company affiliates</p><p>Plan and oversee execution
                    of campaign schedules and budgets</p><p>Lead management off the digital touch points
                    of the organization</p><p>Measure and assess goals versus set ROI</p></>}
            ourRequirements={<><p>Secondary or Bachelor’s degree in a field of marketing or related </p><p>2+ years
                of working experience as a Digital Marketing Specialist </p><p>Possess good
                understanding of digital production and marketing strategies</p><p>Proven experience in
                innovative digital campaign and effective service delivery</p><p>Ability to manage the
                relationship with digital agencies </p><p>Solid understanding of social media marketing
                and campaigning</p><p>Ability to handle digital analysis and web metrics duties</p>
                <p>Very strong communication skills, both written and verbal</p><p>Fluency in
                    English</p></>}
        />
    );
};

export default CareersDigitalMarketingSpecialist;
